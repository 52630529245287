import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Prospect Guest - Join VR Meetings For Free | IrisVR'
const description =
  'Download Prospect today to join VR Meetings and walkthroughs led by licensed Prospect users. Prospect is compatible with Windows devices, and joining as a Guest is free.'
const url = `${getWindowLocationOrigin()}/prospect-viewer`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Prospect.jpg'
const imageWidth = 1200
const imageHeight = 697

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
