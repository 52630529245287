import React from 'react'
import PropTypes from 'prop-types'
import splashVideo from '../../../../images/prospect-viewer/viewer.gif'
import { IrisImg, Mp4Video, IrisCTALinkButton } from '../../../Shared'

const ProspectViewerIntro = ({
  shouldStack,
  launchDownloadModal,
  viewerSplashLogo,
}) => (
  <section
    className={`prospect-viewer-intro ${
      shouldStack ? 'ivr-stacked' : 'ivr-row'
    }`}
  >
    <div className="info-container">
      <div className="logo-container">
        <IrisImg alt="Prospect Viewer" file={viewerSplashLogo} />
      </div>
      <hr />
      <div className="text-container">
        <p className="title">Collaborate in VR without a Prospect account</p>
        <ol className="steps">
          <li>
            <p>Download and open Prospect</p>
          </li>
          <li>
            <p>Navigate to "Guest" and click Continue As Guest</p>
          </li>
          <li>
            <p>Enter your Join Code and your name</p>
          </li>
          <li>
            <p>Click "Launch" to join</p>
          </li>
        </ol>
      </div>
      <div className="button-div">
        <IrisCTALinkButton
          onClick={launchDownloadModal}
          cta={'Download Prospect'}
        />
        <p className="emphasis">(for Windows)</p>
      </div>
    </div>
    <div className="splash-image-container">
      <img alt={'prospect-feature'} className="feature-gif" src={splashVideo} />
    </div>
  </section>
)

ProspectViewerIntro.propTypes = {
  shouldStack: PropTypes.bool.isRequired,
  launchDownloadModal: PropTypes.func.isRequired,
}

export default ProspectViewerIntro
