import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { TextField } from 'redux-form-material-ui'
import { IrisCTALinkButton } from '../../../Shared'

const textFieldConfig = {
  label: 'Your email (Optional)',
  fullWidth: true,
  floatingLabelStyle: {
    color: 'white',
    fontFamily: 'irisvr',
    fontSize: '14px',
    lineHeight: '15px',
  },
  inputStyle: {
    color: 'white',
    spellCheck: false,
    fontFamily: 'irisvr',
  },
  underlineStyle: {
    borderWidth: '2px',
    borderColor: 'white',
  },
  underlineFocusStyle: {
    borderColor: 'white',
  },
}

const DownloadViewer = ({ inputOnChange, launchDownloadModal }) => (
  <div className="download-content-container">
    <div className="download-content">
      <div className="email-input-container">
        <TextField
          {...textFieldConfig}
          onChange={inputOnChange}
          spellCheck={false}
        />
      </div>
      <div className="button-container">
        <IrisCTALinkButton
          onClick={launchDownloadModal}
          cta={'Download Viewer'}
        />
      </div>
      <div className="text-container">
        <p>
          By choosing to download, you agree to the{' '}
          <Link to="/terms">Terms and Conditions.</Link>
        </p>
        <p>
          Please make sure your system meets the requirements prior to
          downloading the Prospect Viewer.
        </p>
      </div>
    </div>
  </div>
)

DownloadViewer.propTypes = {
  inputOnChange: PropTypes.func.isRequired,
  launchDownloadModal: PropTypes.func.isRequired,
}

export default DownloadViewer
