import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProspectViewerIntro from './sections/ProspectViewerIntro'
import Features from './sections/Features'
import Download from './sections/Download'
import './ProspectViewer.global.scss'
import helmetProps from './helmetProps'
import { Layout } from '../Shared'
import DownloadProspectViewerModal from './DownloadProspectViewerModal'

/* eslint-disable no-shadow */
class ProspectViewer extends Component {
  state = { username: '', modalOpen: false }

  inputOnChange = e => this.setState({ username: e.target.value })

  launchDownloadModal = () => {
    this.newsletterSignup()
    this.openModal()
  }

  newsletterSignup = () => {
    const { username } = this.state
    if (username) {
      if (window.analytics) {
        window.analytics.track('Prospect Viewer Newsletter Signup', {
          username,
        })
      }
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { modalOpen } = this.state
    const {
      width,
      viewerSplashLogo,
      viewerMultiuserLogo,
      tooltipIcon,
    } = this.props
    const shouldStack = width < 1100
    return (
      <div id="prospect-viewer">
        <DownloadProspectViewerModal
          mobileClass={width < 480 ? 'mobile' : ''}
          isOpen={modalOpen}
          closeModal={this.closeModal}
          viewerSplashLogo={viewerSplashLogo}
        />
        <ProspectViewerIntro
          shouldStack={shouldStack}
          launchDownloadModal={this.launchDownloadModal}
          viewerSplashLogo={viewerSplashLogo}
        />
        <Features
          viewerMultiuserLogo={viewerMultiuserLogo}
          shouldStack={shouldStack}
        />
        <Download
          shouldStack={shouldStack}
          inputOnChange={this.inputOnChange}
          launchDownloadModal={this.launchDownloadModal}
          tooltipIcon={tooltipIcon}
        />
      </div>
    )
  }
}

ProspectViewer.propTypes = {
  viewerSplashLogo: PropTypes.shape().isRequired,
  viewerMultiuserLogo: PropTypes.shape().isRequired,
  tooltipIcon: PropTypes.shape().isRequired,
}

export default props => (
  <Layout>
    <ProspectViewer {...props} />
  </Layout>
)

export { helmetProps }
