import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import ProspectViewer, { helmetProps } from '../components/ProspectViewer'

export const query = graphql`
  {
    viewerSplashLogo: file(
      relativeDirectory: { eq: "prospect-viewer" }
      name: { eq: "viewer-splash-logo" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 200, height: 39, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    viewerMultiuserLogo: file(
      relativeDirectory: { eq: "prospect-viewer" }
      name: { eq: "viewer-multiuser" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 320) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    tooltipIcon: file(
      relativeDirectory: { eq: "logos" }
      name: { eq: "iris-tooltip-icon" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 19) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <ProspectViewer
      viewerSplashLogo={data.viewerSplashLogo}
      viewerMultiuserLogo={data.viewerMultiuserLogo}
      tooltipIcon={data.tooltipIcon}
    />
  </>
))
