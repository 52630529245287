import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { IrisImg } from '../../../Shared'
import { getSignupUrl } from '../../../../utils/url'

const Features = ({ viewerMultiuserLogo, shouldStack }) => (
  <section
    className={`viewer-features ${shouldStack ? 'ivr-stacked' : 'ivr-row'}`}
  >
    <div className="left-container">
      <div className="image-container">
        <IrisImg alt="Multiuser" file={viewerMultiuserLogo} />
      </div>
    </div>
    <div className="right-container">
      <div className="text-container">
        <p className="title">Instantly collaborate in VR</p>
        <hr className="divider" />
        <p className="p1">
          Collaborate with Prospect users in a shared virtual environment via
          the use of{' '}
          <a
            href="https://help.irisvr.com/hc/en-us/articles/360007790434"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="emphasis">sharing and join codes</span>
          </a>
          .
        </p>
        <p className="p2">
          Interested in creating your own immersive experiences?
        </p>
        <p className="p2.5">
          <a href="https://signup.irisvr.com/step/1">
            <span className="emphasis" style={{ textDecoration: 'underline' }}>
              Start a trial of Prospect today.
            </span>
          </a>
        </p>
      </div>
    </div>
  </section>
)

Features.propTypes = {
  shouldStack: PropTypes.bool.isRequired,
}

export default Features
