import React from 'react'
import PropTypes from 'prop-types'
import Requirements from './Requirements'
import DownloadViewer from './DownloadViewer'

const Download = ({
  shouldStack,
  inputOnChange,
  launchDownloadModal,
  tooltipIcon,
}) => (
  <section
    className={`viewer-download ${shouldStack ? 'ivr-stacked' : 'ivr-row'}`}
  >
    <Requirements tooltipIcon={tooltipIcon} />
    <DownloadViewer
      inputOnChange={inputOnChange}
      launchDownloadModal={launchDownloadModal}
    />
  </section>
)

Download.propTypes = {
  shouldStack: PropTypes.bool.isRequired,
  inputOnChange: PropTypes.func.isRequired,
  launchDownloadModal: PropTypes.func.isRequired,
  tooltipIcon: PropTypes.shape().isRequired,
}

export default Download
