import React from 'react'
import { requirementsCopy } from '../../../Shared'
import { Link } from 'gatsby'

const RequirementBullet = ({ category, copy }) => {
  const isHeadsetCategory = category === 'Headset'
  const displayCopy = isHeadsetCategory ? copy.prospectViewer : copy
  return (
    <li>
      <span style={{ fontWeight: 'bold' }}>
        {category}: <span style={{ fontWeight: 'normal' }}>{displayCopy}</span>
      </span>
    </li>
  )
}

const OculusQuestCaveat = () => {
  return (
    <div style={{ color: '#878787', paddingTop: 20 }}>
      *Prospect for Meta Quest 2 requires special access—request access{' '}
      <span style={{ textDecoration: 'underline' }}>
        <Link to={'/oculus-quest'}>here</Link>
      </span>
      .
    </div>
  )
}

const Requirements = () => (
  <div className="requirements-content-container">
    <div className="requirements-content">
      <div className="system-requirements">
        <p className="header">
          Minimum System Requirements for Prospect by IrisVR
        </p>
        <ul>
          {requirementsCopy.map(({ category, copy }, key) => {
            return (
              <RequirementBullet key={key} category={category} copy={copy} />
            )
          })}
        </ul>
      </div>
      <OculusQuestCaveat />
    </div>
  </div>
)

export default Requirements
