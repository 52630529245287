import React from 'react'
import PropTypes from 'prop-types'
import { ModalWrapper, IrisImg } from '../../Shared'
import './DownloadProspectViewerModal.global.scss'

class DownloadProspectViewerModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    viewerSplashLogo: PropTypes.shape().isRequired,
    closeModal: PropTypes.func.isRequired,
    mobileClass: PropTypes.string.isRequired,
  }

  downloadProspectViewer = () => {
    window.location = 'https://prospect-releases.irisvr.com/ProspectSetup.exe'
    if (window.analytics && this.props.isOpen) {
      window.analytics.track('Prospect Downloaded')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.downloadProspectViewer()
    }
  }

  render() {
    const { isOpen, closeModal, mobileClass, viewerSplashLogo } = this.props

    const manualDownload = (
      <a className="emphasis" onClick={this.downloadProspectViewer}>
        click here
      </a>
    )

    return (
      <ModalWrapper
        className={mobileClass}
        isOpen={isOpen}
        handleClose={closeModal}
        closeIconClassName="download-pv-close-icon"
      >
        <div
          className={`
        download-prospect-viewer-modal
        ${mobileClass}
      `}
        >
          <div className="modal-title-container">
            <IrisImg file={viewerSplashLogo} alt="Prospect Viewer Logo" />
            <p className="modal-title">
              Thank you for downloading the Prospect Viewer.
            </p>
          </div>
          <div className="body-container">
            {mobileClass ? (
              <p>
                Your download should begin momentarily. (If it doesn&#39;t,{' '}
                {manualDownload} to get started.)
              </p>
            ) : (
              <div>
                <p>Your download should begin momentarily.</p>
                <p>(If it doesn&#39;t, {manualDownload} to get started.)</p>
              </div>
            )}
          </div>
        </div>
      </ModalWrapper>
    )
  }
}

export default DownloadProspectViewerModal
